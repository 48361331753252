import styles from "./GameIFrame.module.css";
import { Loader } from "@components";
import { useState, Fragment } from "react";

export const GameIFrame = ({ src, title }) => {
    const [iFrameClassName, setIFrameClassName] = useState(styles.defaultGameIFrame);
    const [loading, setLoading] = useState(true);

    const handleLoad = () => {
        setIFrameClassName(styles.gameIFrame);
        setLoading(false);
    };

    return (
        <Fragment>
            {loading ? <Loader /> : null}
            <iframe
                className={iFrameClassName}
                src={src}
                allowFullScreen={true}
                title={title}
                onLoad={handleLoad}
            ></iframe>
        </Fragment>
    );
};
