import styles from "./GameBody.module.css";
import { Error } from "@components";
import { errorTypes } from "@data";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { GameIFrame } from "./children";

export const GameBody = () => {
  const [searchParams] = useSearchParams();
  const { gameData, getIframeUrl } = useOutletContext();
  const { title } = gameData;

  const isDemo = searchParams.get("demo");
  const iframeUrl = getIframeUrl(isDemo);

  return (
    <div className={styles.gameContainer}>
      <div className={styles.gameWrapper}>
        <GameIFrame src={iframeUrl} title={title} />
      </div>
      <div className={styles.flipNotification}>
        <Error errorType={errorTypes.FLIP_YOUR_DEVICE} />
      </div>
    </div>
  );
};
